import React from 'react';
import {Link} from '@reach/router';

const ShortFooter = () => {
    return(
    <>
        <footer>
            <div className="w-full flex flex-col flex-nowrap items-center">
                <hr className="w-full bg-ns-gray-accent "></hr>
                <div className="pt-2 mb-2">
                    <Link to="/Impressum" className="text-ns-gray hover:text-ns-pink font-RotisLight text-sm">IMPRESSUM | </Link> 
                    <Link to="/Datenschutz" className="text-ns-gray hover:text-ns-pink font-RotisLight text-sm">DATENSCHUTZ</Link>
                </div>
            </div>
        </footer>
    </>
    
)};

export default ShortFooter;