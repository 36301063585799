import React from 'react';

/* Import Components */
import HeroSection from '../HeroSectionnoimage.js';

const Dsgvo = () => {

    return(
        <>
        <HeroSection
        image=""
        headline=""
        textUpper="Datenschutzerklärung"
        />
        <div className=" p-4 lg:p-10 flex flex-col w-full lg:w-10/12 m-auto">
            <div className="mb-3">
                <h3 className="font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">I. Name und Anschrift des Verantwortlichen</h3>
                <p className="font-RotisLight text-ns-gray text-sm">
                Die Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:
                Nicole Simon<br/>
                Ahornweg 33<br/>
                71120 Grafenau<br/>
                Telefon: 07033 693165<br/>
                Email: info@nicolesimon.de<br/>
                Internetadresse: www.nicolesimon.de<br/>
                </p>
            </div>

            <div className="mb-3">
                <h3 className="font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">Allgemeines zur Datenverarbeitung</h3>
                <p className="font-RotisBold text-ns-gray text-sm">1. Umfang der Verarbeitung personenbezogener Daten</p>
                <p className="font-RotisLight text-ns-gray text-sm mb-3">
                Bei Nutzung der Website werden keinerlei personenbezogene Daten erhoben oder verwendet.  Eine Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt nur nach Einwilligung des Nutzers bei seiner Kontaktaufnahme. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
                </p>
                <p className="font-RotisBold text-ns-gray text-sm">2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</p>
                <p className="font-RotisLight text-ns-gray text-sm mb-3">
                Soweit ich für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einhole, dient Art.6 Abs.1 lit. EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
  Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs.1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
  Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs.1 lit. c DSGVO als Rechtsgrundlage.
  Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art.6 Abs.1 lit. d DSGVO als Rechtsgrundlage.
  Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs.1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
                </p>
                <p className="font-RotisBold text-ns-gray text-sm">3. Datenlöschung und Speicherdauer</p>
                <p className="font-RotisLight text-ns-gray text-sm mb-3">
                Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                </p>
            </div>

            <div className="mb-3">
                <h3 className="font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">III. Bereitstellung der Website und Erstellung von Logfilesg</h3>

                <p className="font-RotisLight text-ns-gray text-sm">Einen Vertrag zur Auftragsverarbeitung (AVV) muss nach EU-Datenschutz-Grundverordnung (DSGVO) jede verantwortliche Stelle abschließen, die personenbezogene Daten im Auftrag verarbeiten lässt.
Als Kunde von “netlify, werden unter Umständen personenbezogene Daten auf den Servern von “netlify” gespeichert. Sobald personenbezogene Daten auf den Systemen von “netlify” gespeichert bzw. verarbeitet werden, müssen wir uns nach Art. 28 DSGVO rechtlich absichern.
Aus diesem Grund habe ich einen Vertrag zur Auftragsverarbeitung (AVV) mit “netlify” abgeschlossen. Mit dieser Vereinbarung haben meine Kunden bzw. Personen, die elektronisch Kontakt aufnehmen, die Sicherheit der Daten auf den Servern von “netlify”
Damit ist die explizite Bestätigung der rechtskonformen Umsetzung der gesetzlichen Vorgaben zum Datenschutz durch “netlify.com” sichergestellt.  Bei Nutzung der Website werden keinerlei personenbezogene Daten erhoben oder verwendet.  Eine Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt nur nach Einwilligung des Nutzers bei seiner Kontaktaufnahme. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
              </p>
            </div>

            <div className="mb-3">
                <h3 className="font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">IV. Kontaktformular, E-Mail-Kontakt und Newsletter</h3>
                <p className="font-RotisBold text-ns-gray text-sm">1. Beschreibung und Umfang der Datenverarbeitung</p>
                <p className="font-RotisLight text-ns-gray text-sm mb-3">
                Auf meiner Internetseite ist kein eigenständiges Kontaktformular vorhanden. Der Kontakt wird über meine, dort stehende, geschäftliche Email Adresse aufgenommen.
Dabei werden die bei der Kontaktaufnahme mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert. Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.
                </p>
                <p className="font-RotisBold text-ns-gray text-sm">2. Rechtsgrundlage für die Datenverarbeitung</p>
                <p className="font-RotisLight text-ns-gray text-sm mb-3">
                Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
  Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
                </p>
                <p className="font-RotisBold text-ns-gray text-sm">3. Zweck der Datenverarbeitung</p>
                <p className="font-RotisLight text-ns-gray text-sm mb-3">
                Die Verarbeitung der personenbezogenen Daten dient allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten..
                </p>
                <p className="font-RotisBold text-ns-gray text-sm">4. Dauer der Speicherung</p>
                <p className="font-RotisLight text-ns-gray text-sm mb-3">
                Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist.
                </p>
            </div>

        </div>
        </>
)};

export default Dsgvo;
