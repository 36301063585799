import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_philosophie.jpg';

const Philosophie = () => {

return(

    <>
        <Helmet>
            <meta
            name="description"
            content="Für mich sind es Privileg und Berufung, Entwicklungen zu begleiten."/>
        </Helmet>
        <HeroSection
        image={HomeHeroImage}
        headline={"Philosophie"}
        textUpper={"Für mich sind es Privileg und Berufung,"}
        textLower={"Entwicklungen zu begleiten"}
        />
        <div className="lg:w-10/12 m-auto pr-4 pl-4">
            <div className="flex flex-col">
                <div className="flex flex-col lg:flex-row nowrap">
                    <div className="flex flex-col flex-grow lg:pr-4 halfrow">
                    <h3 className="mt-6 font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">Vernetzt und ganzheitlich</h3>
                    <span class="text-ns-gray font-RotisLight text-sm">Jedes Unternehmen, Team, jedes Individuum ist ein einzigartiges
                    System, das mit sich und anderen in Wechselwirkung steht. Um Dinge
                    positiv zu ändern, ist es mir wichtig, das Ganze zu überschauen,
                    den Blick fürs Wesentliche ebenso wie für scheinbar Nebensächliches
                    zu haben.</span>

                    <h3 className="mt-6 font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">Individuell und maßgeschneidert</h3>
                      <span class="text-ns-gray font-RotisLight text-sm">
                    Beobachten, verstehen, bewerten, umsetzen - Beratung und Entwicklung
                    bedeuten individuelle und maßgeschneiderte Konzepte
                    und Impulse für Veränderungsprozesse.</span>
                    <h3 className="mt-6 font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">Ziel- und ressourcenorientiert</h3>
                      <span class="text-ns-gray font-RotisLight text-sm">
                    Mein Vorgehen basiert auf existierenden wirtschaftlichen wie persönlichen
                    Ressourcen. Ziel ist es, die vorhandenen Stärken zu intensivieren
                    und noch nicht freigesetzte Energien zu aktivieren.</span>
                  </div>
                  <div className="flex flex-col flex-grow-0 w-full lg:w-1/2 lg:pl-4 halfrow">
                  <h3 className="mt-6 font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">Bewegend und bewahrend</h3>
                  <span class="text-ns-gray font-RotisLight text-sm">
                  In jedem Prozess ist es wichtig, die Balance zwischen Verändern und
                  Bewahren zu halten.</span>
                  <h3 className="mt-6 font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">Professionell und pragmatisch</h3>
                    <span class="text-ns-gray font-RotisLight text-sm">
                  Die Konzepte werden ganz pragmatisch auf die jeweilige Situation
                  angepasst. Meine professionelle Vorgehensweise basiert sowohl auf
                  meiner fundierten Ausbildung als auch auf meiner langjährigen Erfahrung.
                  Sie ist für meine Kunden jeder Zeit transparent und nachvollziehbar.</span>
                  <h3 className="mt-6 font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">Wertschätzend und authentisch</h3>
                    <span class="text-ns-gray font-RotisLight text-sm">
                  Meine Haltung gegenüber Menschen ist geprägt von Respekt, Wertschätzung
                  und Offenheit.</span>
                  </div>
                </div>
              </div>
            </div>

    </>

)};

export default Philosophie;
