import React from 'react';

const ImageGrid = ({children}) => (
    <>
    <div className="bg-teaser-Veraenderungsprozesse bg-teaser-Teamentwicklung bg-teaser-Moderationen bg-teaser-Konfliktklaerung bg-teaser-Coaching bg-teaser-Seminare hidden"></div>
        <div className="flex flex-row flex-wrap justify-center">
            {children}
        </div>
    </>
);

export default ImageGrid;
