import React from 'react';

/* Import Components */
import HeroSection from '../HeroSection.js';
import ImageGrid from '../ImageGrid.js';
import LeadImage from '../LeadImage.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_home.jpg';

const Home = ({dropdownMenuItems}) => {

    return(
        <>
            <HeroSection
            image={HomeHeroImage}
            metatext={"'Unternehmensberatung und Personalentwicklung: Das Ganze sehen. Verstehen. Etwas bewegen.'"}
            headline={"Unternehmensberatung & Personalentwicklung"}
            textUpper={'Das Ganze sehen.'}
            textLower={'Verstehen. Etwas bewegen.'}
            />
            <ImageGrid>
                {dropdownMenuItems.map((value, key) => {
                    return <LeadImage lead={value[1]} link={value[0]} key={key} />
                })}
            </ImageGrid>
            <div className="mb-5 border-ns-pink border-solid border-1 p-7">
                <p className="text-ns-pink font-RotisLight text-xl lg:text-lg text-center">
                    Alle Angebote sind als Präsenz- & Online-Formate möglich!
                </p>
            </div>
        </>
    )};
export default Home;
