import React from 'react';

const LeadText = ({headline, content}) => {

    return(
        <>
            <div className={"h-auto w-full lg:w-1/2 lg:pl-11 lg:odd:pr-5 lg:even:pl-5 max-w-2xl leadtext" + (headline === "" && content === "" ? "" : "")}>
                <h3 className="font-RotisBold text-ns-gray text-lg mb-3 leading-lineheighth2">{headline}</h3>
                <p className="text-ns-gray font-RotisLight text-sm">{content}</p>
            </div>
        </>

)};

export default LeadText;
