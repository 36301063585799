import React from 'react';
import {Link} from '@reach/router';

const LeadImage = ({lead, link}) => {
    return(
        <>
            <div className="imgwrapper md:w-1/2 w-full flex-grow md:flex-grow-0 pb-8 md:odd:pr-4 md:even:pl-4">
                <Link to={link}>
                    <div className={"bg-center bg-no-repeat bg-cover bg-teaser-"+ link +" homeimages w-full flex justify-center items-center"}>
                        <div className="bg-white border-ns-gray-accent border-1 flex flex-col items-center w-11/12 w-teasertext h-auto p-4">
                            <span className=" text-xl font-RotisLight text-ns-gray pb-1">{lead}</span>
                            <span className=" text-xs md:text-xs font-RotisLight text-ns-gray">Mehr Informationen -></span>
                        </div>
                    </div>
                </Link>
            </div>
        </>
)};

export default LeadImage;
