import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import RefText from '../RefText.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_referenzen.jpg';

const Referenzen = () => {

const ref = ["Daimler AG",
    "Komet Group",
    "Robert Bosch GmbH",
    "DHBW CAS Heilbronn",
    "Dürr Dental",
    "Gewerbliche Schule im Hoppenlau mit ",
    "technischer Oberschule Stuttgart",
"KIT Karlsruhe",
"Stadt Esslingen",
"Stadt Stutensee",
"Stadt Wörth am Rhein",
"Rikker Holzbau GmbH"];

const net = ["BPO Beratergruppe"];

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Kunden und Stimmen"/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={"Referenzen"}
            textUpper={"Kunden &"}
            textLower={"Stimmen"}
            />
            <div className="pr-4 pl-4 lg:pr-14 lg:pl-14">
                <div className="flex flex-col">
                    <span className="font-RotisBold text-ns-gray text-base lg:text-lg mb-3">Original Töne:</span>
                    <div className="flex flex-col lg:flex-row nowrap">
                        <div className="flex flex-col lg:pr-4 lg:w-1/2">
                            <RefText
                            headline='"Mit ausgeprägtem Gespür für Gruppenprozesse und innovativem
                            Methodeneinsatz eine großartige Unterstützung - insbesondere
                            auch in digitalen Workshopformaten"'
                            person="Cornelia Mayer"
                            abteilung="Abteilungsleiterin Daimler AG"
                            />
                            <RefText
                            headline='"Die Augen für die persönlichen Ressourcen der
                            Mitarbeiter*innen zu öffnen ist Frau Simons großes Talent. Sie
                            hält sich nicht mit Schwächen auf, sondern arbeitet mit und für
                            die Stärken des Teams. Dabei zeichnet sie sich besonders durch
                            ihre Fähigkeit aus, Menschen anzuleiten, unterschiedlichste
                            Blickwinkel in lösungsorientierte Handlungen umzusetzen und
                            diese zu einem positiven Gesamtergebnis zu bringen."'
                            person="Stadtverwaltungsrat Tobias Simon"
                            abteilung="Diplom-Verwaltungsbetriebswirt (FH)
                            Personalleiter Stadtverwaltung Wörth am Rhein"
                            />
                        </div>
                        <div className="flex flex-col lg:w-1/2 lg:pl-4">
                            <RefText
                            headline='"Ein herzliches Dankeschön für die immer inspirierende Unterstützung,
                            Beratung und Moderation der Schulentwicklungsplanung
                            unserer Stadt. Sie sind uns eine wichtige Impulsgeberin
                            und haben wesentlich mit dazu beigetragen, die verschiedenen
                            Akteure aus Politik, Schulen, Verwaltung und Elternschaft zusammenzubringen,
                            bei komplexen Sachverhalten Lösungen zu
                            finden und konstruktive Ergebnisse zu erzielen."'
                            person="Bernd Berroth"
                            abteilung="Leiter Amt für Bildung, Erziehung und Betreuung der Stadt
                            Esslingen/N."
                            />
                            <RefText
                            headline='"Eine Teilnehmer*innenstimme aus unseren
                            Persönlichkeitsentwicklungs-Seminaren fasst sehr treffend unsere
                            durchweg positiven Erfahrungen mit den Trainings von Frau Simon
                            zusammen: Besonders positiv waren die vielen Beispiele, Tipps und
                            Tricks, die Kleingruppenarbeiten, der offene Austausch in der Gruppe
                            und mit der Dozentin, das Angebot zum Coaching und die kurze Videos,
                            die die Problematik gut erläutern und auf den Punkt gebracht haben."'
                            person="Annette Ott"
                            abteilung="Leiterin Fachübergreifende Kompetenzen an der DHBW CAS Heilbronn"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row nowrap">
                        <div className="flex flex-col lg:w-1/2">
                            <h2 className="font-RotisBold text-ns-gray text-base lg:text-lg mt-8 mb-3">Referenzen (Auszug)</h2>
                            <ul className="list-none">
                                {ref.map((value,key) => {
                                    return <li className="text-ns-gray font-RotisLight text-xs lg:text-sm">{value}</li>
                                })}

                            </ul>
                        </div>
                        <div className="flex flex-col lg:pl-4">
                            <h2 className="font-RotisBold text-ns-gray text-base lg:text-lg mt-8 mb-3">Netzwerk</h2>
                                <ul className="list-none">
                                    {net.map((value,key) => {
                                        return <li className="text-ns-gray font-RotisLight text-xs lg:text-sm">{value}</li>
                                    })}

                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
)};

export default Referenzen;
