import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import TextGrid from '../TextGrid.js';
import LeadText from '../LeadText.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_moderationen.jpg';
import LeadList from '../LeadList.js';

const Moderationen = () => {

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Den roten Faden behalten."/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={"MODERATIONEN"}
            textUpper={"Den roten"}
            textLower={"Faden behalten"}
            />
            <TextGrid>
                <LeadText
                headline="Ziele effizient erreichen"
                content="Ein großes Methodenrepertoire, differenzierte Wahrnehmung und
                ein gutes Gespür für Gruppenprozesse ermöglichen mir effizientes
                und zielorientiertes Arbeiten in unterschiedlich großen Gruppen. Die
                konsequente Steuerung Ihrer Veranstaltung durch neutrale Moderation,
                punktgenaues Zeitmanagement und klare Fokussierung schaffen
                eine gute Arbeitsatmosphäre sowie tragfähige Entscheidungen
                und Ergebnisse."
                />
                <LeadList
                headline="MÖGLICHE FORMATE:"
                content={["Klausuren", "Workshops", "Sitzungen", "Projekt-Veranstaltungen", "Kick-Offs", "Meetings", "Zukunfts-Workshops …"]}
                withsubheadding={[false, ""]}
                />
                <LeadText
                headline=""
                content=""
                />
                <LeadList
                headline="MÖGLICHE INHALTE:"
                content={["Fachinhaltliche Themen und Lösungen", "Rollen- und Aufgabenverteilung sowie Verantwortlichkeiten", "Informations- und Kommunikationsprozesse", "Konfliktklärungen"]}
                withsubheadding={[false, ""]}
                />
            </TextGrid>
        </>
)};

export default Moderationen;