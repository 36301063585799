import {Router, Match} from '@reach/router';

/* Import Site Elements */
import Navigation from './components/Navigation.js';
import Footer from './components/Footer.js';
import ShortFooter from './components/ShortFooter.js';
import ScrollToTop from './components/ScrollToTop.js';

/* Import Sites */
import Home from './components/sites/Home.js';
import NotFound from './components/sites/NotFound.js';
import Philosophie from './components/sites/Philosophie.js';
import VProzesse from './components/sites/VProzesse.js';
import Vita from './components/sites/Vita.js';
import TeamEntwicklung from './components/sites/TeamEntwicklung.js';
import Konflikt from './components/sites/Konflikt.js';
import Moderationen from './components/sites/Moderationen.js';
import Coaching from './components/sites/Coaching.js';
import Seminare from './components/sites/Seminare.js';
import Kontakt from './components/sites/Kontakt.js';
import Referenzen from './components/sites/Referenzen.js';
import Impressum from './components/sites/Impressum.js';
import DSGVO from './components/sites/Dsgvo.js';

const App = () => {

const dropdownMenuItems = [["Veraenderungsprozesse", "Veränderungsprozesse"], ["Teamentwicklung", "Teamentwicklung"], ["Konfliktklaerung", "Konfliktklärung"], ["Moderationen", "Moderationen"], ["Coaching", "Coaching"], ["Seminare", "Seminare"]];
const firstDropMenu = ["Philosophie", "Vita"];

return(
  <>
    <div className="max-w-ns-lg m-4 mainwrapper xl:m-auto">
      <Navigation
      secondDropMenu={dropdownMenuItems}
      firstDropMenu={firstDropMenu}
      />

      <div className="maincontent">
        <Router primary={false}>
          <ScrollToTop path="/">
            <Home path="/" dropdownMenuItems={dropdownMenuItems} />
            <Philosophie path="/Philosophie" />
            <VProzesse path="/Veraenderungsprozesse" />
            <Vita path="/Vita" />
            <TeamEntwicklung path="/Teamentwicklung" />
            <Konflikt path="/Konfliktklaerung" />
            <Moderationen path="Moderationen" />
            <Coaching path="/Coaching" />
            <Seminare path="/Seminare" />
            <Referenzen path="/Referenzen" />
            <Kontakt path="/Kontakt" />
            <Impressum path="/Impressum" />
            <DSGVO path="/Datenschutz" />
            <NotFound default />
          </ScrollToTop>
        </Router>
        </div>
        <Match path="/Kontakt">
          {props => props.match ? (
              <ShortFooter/>
          ) : (
            <Footer/>
          )}
        </Match>
    </div>
  </>
)};

export default App;
