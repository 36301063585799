import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import TextGrid from '../TextGrid.js';
import LeadText from '../LeadText.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_teamentwicklung.jpg';
import LeadList from '../LeadList.js';

const TeamEntwicklung = () => {

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Leistungsfähige Teams formieren, um gemeinsam erfolgreich zu sein."/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={"Teamentwicklung"}
            textUpper={"Leistungsfähige Teams formieren,"}
            textLower={"um gemeinsam erfolgreich zu sein"}
            />
            <TextGrid>
                <LeadText
                headline="Empowerment"
                content="Die Zusammenarbeit, Kommunikationsstruktur und Konfliktkompetenz
                in Teams sind entscheidende Faktoren für erfolgreiche Unternehmen
                und Organisationen. Damit in Teams lösungsorientiert
                miteinander gearbeitet werden kann, müssen gleichermaßen die
                strukturellen und organisatorischen Rahmenbedingungen passen,
                wie auch eine Vertrauens- und Zusammenarbeitskultur etabliert
                werden. Die Orientierung an vorhandenen Stärken und Ressourcen,
                gepaart mit Leichtigkeit und Freude am Ausprobieren, ermöglicht
                Empowerment. So werden neue Tools und Feedbackformate gelernt
                und können stimmig in den Arbeitsalltag integriert werden."
                />
                <LeadList
                headline="MÖGLICHE INHALTE:"
                content={["Zukunftsfähige Ausrichtung","Zukunftsbild und Purpose", "Rollen und Aufgaben", "Agiltät und Selbstorganisation", "Meeting- und Kommunikationsformate", "Konflikte", "Feedback"]}
                withsubheadding={[false, ""]}
                />
            </TextGrid>
        </>
)};

export default TeamEntwicklung;
