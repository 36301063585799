import React from 'react';

const TextGrid = ({children}) => (
    <>
        <div className="flex flex-row flex-wrap justify-center textwrapper">
            {children}
        </div>
    </>
);

export default TextGrid;
