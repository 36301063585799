import React, { useState } from 'react';
import {Link, Match} from '@reach/router';

import Logo_lg_mb from './images/nicolesimon_logo_mobile.svg';
import Logo_lg_lg from './images/nicolesimon_logo_reduziert.svg';

const Navigation = ({secondDropMenu, firstDropMenu}) => {

const [navbarOpen, setNavbarOpen] = useState(false);

return(
    <>
        <div className="flex flex-col lg:flex-row lg:flex-nowrap lg:justify-around mb-5 lg:mb-0">
            <div className="flex relative top-0 right-0 z-20 flex-row flex-nowrap justify-between">
                <div className="flex justify-start">
                    <Link to="/">
                        <img src={Logo_lg_mb} alt="logo mobile" className={"logomobil mr-auto lg:hidden " + (navbarOpen ? "hidden" : "block")} />
                        <img src={Logo_lg_lg} alt="logo desktop" className="mr-auto hidden lg:block" />
                    </Link>
                </div>
                <div className="flex flex-col lg:hidden">
                    <button type="button" className="block navbtn" onClick={() => setNavbarOpen(!navbarOpen) }>
                        <svg className="h-14 w-14 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {navbarOpen && (
                            <path fillRule="evenodd" fill="#7d827e" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            )}
                            {!navbarOpen && (
                            <path fillRule="evenodd" fill="#7d827e" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                            )}
                        </svg>
                    </button>
                </div>
            </div>
            <div className="flex lg:align-baseline">
                <div className={"navwrapper flex-grow items-center lg:flex" + (navbarOpen ? " flex" : " hidden")}>
                    <ul className={"flex flex-col lg:flex-row list-none lg:ml-auto " + (!navbarOpen ? "normnav" : "mobilnav") }>
                        <li className="pr-2">
                            <Match path="/">
                                {props => props.match ? (
                                    <Link to="/" onClick={() => setNavbarOpen(!navbarOpen)} className="text-ns-pink font-RotisLight tracking-wideopti text-base lg:text-navi">HOME</Link>
                                ) : (
                                    <Link to="/" onClick={() => setNavbarOpen(!navbarOpen)} className="text-ns-gray hover:text-ns-pink tracking-wideopti font-RotisLight text-base lg:text-navi">HOME</Link>
                                )
                            }
                            </Match>
                        </li>
                        <li className="pr-2 hidden lg:flex">
                            <span className="text-ns-gray font-RotisLight text-md border-r-1-5 border-ns-gray h-font my-auto"></span>
                        </li>
                        <li className="pr-2">
                            <div className="group inline-block relative">
                                <span className="text-ns-gray font-RotisLight tracking-wideopti text-base lg:text-navi cursor-pointer">NICOLE SIMON</span>
                                <ul className=" bg-white relative z-10 w-64 lg:absolute hidden opacity-90 text-ns-gray pt-1 group-hover:block">
                                {firstDropMenu.map((value) => {
                                    return(
                                        <li className="px-4 pb-4">
                                            <Match path={value}>
                                                {props => props.match ? (
                                                    <Link to={value} onClick={() => setNavbarOpen(!navbarOpen)} className="font-RotisLight leading-xl text-ns-pink border-b-0-5 border-dotted text-base lg:text-md block whitespace-no-wrap">{value}</Link>
                                                ) : (
                                                    <Link to={value} onClick={() => setNavbarOpen(!navbarOpen)} className="font-RotisLight leading-xl hover:text-ns-pink border-b-0-5 border-dotted text-base lg:text-md block whitespace-no-wrap">{value}</Link>
                                                )}
                                            </Match>
                                        </li>
                                    )
                                    })}
                                </ul>
                            </div>
                        </li>
                        <li className="pr-2 hidden lg:flex">
                            <span className="text-ns-gray font-RotisLight text-md border-r-1-5 border-ns-gray h-font my-auto"></span>
                        </li>
                        <li className="pr-2">
                            <div className="group inline-block relative">
                                <span className="text-ns-gray font-RotisLight text-base lg:text-navi tracking-wideopti cursor-pointer">LEISTUNGEN</span>
                                <ul className=" bg-white relative z-10 w-64 lg:absolute hidden opacity-90 text-ns-gray pt-1 group-hover:block">
                                {secondDropMenu.map((value) => {
                                    return(
                                        <li className="px-4 pb-4">
                                            <Match path={value[0]}>
                                                {props => props.match ? (
                                                    <Link to={value[0]} onClick={() => setNavbarOpen(!navbarOpen)} className="font-RotisLight leading-xl text-ns-pink border-b-0-5 border-dotted text-base lg:text-md block whitespace-no-wrap">{value[1]}</Link>
                                                ) : (
                                                    <Link to={value[0]} onClick={() => setNavbarOpen(!navbarOpen)} className="font-RotisLight leading-xl hover:text-ns-pink border-b-0-5 border-dotted text-base lg:text-md block whitespace-no-wrap">{value[1]}</Link>
                                                )}
                                            </Match>
                                        </li>
                                    )
                                    })}
                                </ul>
                            </div>
                        </li>
                        <li className="pr-2 hidden lg:flex">
                            <span className="text-ns-gray font-RotisLight text-md border-r-1-5 border-ns-gray h-font my-auto"></span>
                        </li>
                        <li className="pr-2">
                            <Match path="/Referenzen">
                                {props => props.match ? (
                                    <Link to="/Referenzen" onClick={() => setNavbarOpen(!navbarOpen)} className="text-ns-pink tracking-wideopti font-RotisLight text-base lg:text-navi">REFERENZEN</Link>
                                ) : (
                                    <Link to="/Referenzen" onClick={() => setNavbarOpen(!navbarOpen)} className="text-ns-gray tracking-wideopti hover:text-ns-pink font-RotisLight text-base lg:text-navi">REFERENZEN</Link>
                                )
                                }
                            </Match>
                        </li>
                        <li className="pr-2 hidden lg:flex">
                            <span className="text-ns-gray font-RotisLight text-md border-r-1-5 border-ns-gray h-font my-auto"></span>
                        </li>
                        <li className="pr-2">
                            <Match path="/Kontakt">
                                {props => props.match ? (
                                    <Link to="/Kontakt" onClick={() => setNavbarOpen(!navbarOpen)} className="text-ns-pink tracking-wideopti font-RotisLight text-base lg:text-navi">KONTAKT</Link>
                                ) : (
                                    <Link to="/Kontakt" onClick={() => setNavbarOpen(!navbarOpen)} className="text-ns-gray tracking-wideopti hover:text-ns-pink font-RotisLight text-base lg:text-navi">KONTAKT</Link>
                                )
                                }
                            </Match>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
)};

export default Navigation;
