import React from 'react';
import { Helmet } from 'react-helmet';


import Logo_sm from './images/nicolesimon_logo_solo.svg';


const HeroSection = ({image, headline, textUpper, textLower}) => (
    <>
    <Helmet>
        <title>{headline !== "" ? "Nicole Simon - " + headline : "Nicole Simon" }</title>
        <meta name="keywords" content="Solution Focus, Lösungsorientierung, Agilität, Teamentwicklung, Systemische Beratung, Change Management" />
    </Helmet>

        <h2 className="text-ns-gray font-RotisLight mx-auto py-4 mt-7 mb-1 text-center leading-xl tracking-wideopti text-base lg:text-base ">{headline.toUpperCase()}</h2>
        <h1 className="text-sizeh1 leading-lineheighth1 text-ns-pink font-RotisLight mx-auto text-center max-w-hero">{textUpper}<br />{textLower}</h1>
        <img src={Logo_sm} alt="logo-small" className="mx-auto block mt-7 mb-10"></img>
    </>
);

export default HeroSection;
