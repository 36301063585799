import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import TextGrid from '../TextGrid.js';
import LeadText from '../LeadText.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_coaching.jpg';
import LeadList from '../LeadList.js';

const Coaching = () => {

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Persönliche Weiterentwicklung"/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={"COACHING"}
            textUpper={"Persönlich"}
            textLower={"Weiterentwickeln"}
            />
            <TextGrid>
                <LeadText
                headline="Wirksamkeit entfalten"
                content="Beim Coaching liegt der Fokus auf individuellen Stärken und Ressourcen,
                dabei werden gemeinsam neue Perspektiven und Handlungsoptionen
                erkundet. Inspiriert durch die Methoden des Solution
                Focus, die Klarheit und Leichtigkeit verbinden, werden konkrete
                nächste Schritte definiert."
                />
                <LeadList
                headline="MÖGLICHE THEMEN:"
                content={["Persönlichen Standortbestimmung", "Berufliche (Neu-)Orientierung", "Rollenwechsel (erste Führungsverantwortung, Führungswechsel)", "Neue Perspektiven und Handlungsoptionen entwickeln", "Klarheit bei Entscheidungen gewinnen", "Herausfordernde Situationen kompetent meistern", "Selbststeuerung, Resilienz und Leichtigkeit", "Female Empowerment", "Resilienz"]}
                withsubheadding={[false, ""]}
                />
            </TextGrid>
        </>
)};

export default Coaching;
