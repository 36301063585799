import React from 'react';

const RefText = ({headline, person, abteilung}) => {

    return(
        <>
            <div className="flex flex-col mb-8">
                <span className="text-ns-gray font-RotisBold text-sm">{headline}</span>
                <span className="text-ns-gray font-RotisLight text-sm">{person}</span>
                <span className="text-ns-gray font-RotisLight text-sm">{abteilung}</span>
            </div>
        </>
)};

export default RefText;
