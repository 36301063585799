import React from 'react';

/* Import Components */
import HeroSection from '../HeroSectionnoimage.js';


const Impressum = () => {

    return(
        <>
        <HeroSection
        image=""
        headline=""
        textUpper="Impressum"
        />
        <div className=" p-4 lg:p-10 flex flex-col w-full lg:w-10/12 m-auto">
            <div className="flex flex-col">
                <span className="font-RotisBold text-xs lg:text-sm text-ns-gray">Website-Anbieter:</span>
                <p className="font-RotisLight text-ns-gray text-xs lg:text-sm">
                nicole simon<br/>
                Beratung & Entwicklung<br/>
                Ahornweg 33, 71120 Grafenau<br/>
                Tel.: 07033.693165<br/>
                Fax.: 07033.693166<br/>
                info@nicolesimon.de<br/>
                www.nicolesimon.de<br/>
                </p>
            </div>
            <div className=" mt-4 flex flex-col">
                <span className="font-RotisBold text-xs lg:text-sm text-ns-gray">Umsatzsteuer-ID</span>
                <p className="font-RotisLight text-ns-gray text-xs lg:text-sm">
                DE250260378
                </p>
            </div>
            <div className=" mt-4 flex flex-col">
                <span className="font-RotisBold text-xs lg:text-sm text-ns-gray">Umsetzung</span>
                <p className="font-RotisLight text-ns-gray text-xs lg:text-sm">
                Gestaltung und Layout: schneewolf creation, <a className="text-ns-pink" href="https://www.schneewolf.de"> www.schneewolf.de</a><br/>
                Technik und Programmierung: Jonas Reidel, <a className="text-ns-pink" href="https://www.jonasreidel.de"> www.jonasreidel.de</a><br/>
                Technik Feinschliff: Alex-Schwarz, <a className="text-ns-pink" href="https://www.alex-schwarz.com"> www.alex-schwarz.com</a>
                </p>
            </div>
            <div className=" mt-4 flex flex-col">
                <span className="font-RotisBold text-xs lg:text-sm text-ns-gray">credits:</span>
                <p className="font-RotisLight text-ns-gray text-xs lg:text-sm">
                Startseite, Kontakt: Andreas Dalferth Photos<br/>
                Philosophie, Vita: Melanie März, Fotografie<br/>
                Veränderungsprozesse, Moderation, Coaching: photocase<br/>
                Teamentwicklung, Konfliktklärung: AdobeStock<br/>
                Seminare: shutterstock<br/>
                </p>
            </div>
            <div className=" mt-4 flex flex-col">
                <span className="font-RotisBold text-xs lg:text-sm text-ns-gray">Haftungshinweis</span>
                <p className="font-RotisLight text-ns-gray text-xs lg:text-sm">
                Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
ausschließlich deren Betreiber verantwortlich.
                </p>
            </div>
        </div>
        </>
)};

export default Impressum;
