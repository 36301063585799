import React from 'react';

import MailIcon from './images/nicolesimon_sideicons_mail.svg';
import PhoneIcon from './images/nicolesimon_sideicons_phone.svg';

const SideIcons = () => {

    return(
        <>
            <div className="absolute sidebtns right-0">
                <div className="flex flex-col flex-nowrap">
                    <a href="tel:07033693165" className="" >
                        <img src={PhoneIcon} alt="Phone" className="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20"/>
                    </a>
                    <a href="mailto:info@nicolesimon.de" className="" >
                        <img src={MailIcon} alt="Phone" className="w-14 h-14 md:w-20 md:h-20 lg:w-20 lg:h-20"/>
                    </a>
                </div>
            </div>
        </>
)};

export default SideIcons;
