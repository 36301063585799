import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import TextGrid from '../TextGrid.js';
import LeadText from '../LeadText.js';

/* Import image */
import HomeHeroImage from '../images//heroImages/header_seminare.jpg';
import LeadList from '../LeadList.js';

const Seminare = () => {

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Fokussiert auf das Ziel"/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={"SEMINARE"}
            textUpper={"Fokussiert"}
            textLower={"auf das Ziel"}
            />
            <TextGrid>
                <LeadText
                headline="Die eigenen Kompetenzen weiterentwickeln"
                content="Bei Seminaren stehen die persönliche Weiterentwicklung und die
                Zielerreichung des Einzelnen im Vordergrund. Ich arbeite mit direkt
                anwendbaren Modellen und Tools zum Ausprobieren und Umsetzen.
                In den Trainings kombiniere ich bewusst Leichtigkeit mit Tiefgang.
                Erfahrungsaustausch in der Gruppe fördert Selbsterkenntnis."
                />
                <LeadList
                headline="MEINE SEMINARTHEMEN:"
                content={["Stärken stärken – Selbstmarketing und Ressourcenaktivierung", "Führung - ist das was für mich?", "Female Empowerment – Spezifika für Frauen", "Persönliche Standortbestimmung und Karriereplanung", "Selbststeuerung und Resilienz", "Kommunikation und Gesprächsführung"]}
                withsubheadding={[true, "INDIVIDUAL:"]}
                />
                <LeadText
                headline=""
                content=""
                />
                <LeadList
                headline=""
                content={["Agilität und Eigenverantwortung", "Meetingformate und Kommunikation", "Moderation und Präsentation", "Konfliktklärung", "Change Management"]}
                withsubheadding={[true, "TEAM:"]}
                />
            </TextGrid>
            <div className="mb-5 border-ns-pink border-solid border-1 p-7 mt-4">
                <p className="text-ns-pink font-RotisLight text-xl lg:text-lg text-center">
                    Alle Angebote sind als Präsenz- & Online-Formate möglich!
                </p>
            </div>
        </>
)};

export default Seminare;
