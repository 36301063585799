import React from 'react';

const LeadList = ({headline, content, withsubheadding}) => {

return(

    <>
        <div className={"h-auto w-full lg:w-1/2 lg:odd:pr-5 lg:even:pl-5 max-w-2xl leading-list-wrapper"+ (headline === "" && withsubheadding !== "" ? "" : "")}>
            <span className="text-ns-gray leading-list font-RotisBold">{headline}</span>
            <ul className="list-inside list-disc block ">
            <span className="text-ns-gray font-RotisLight text-xs lg:text-md" >
                {withsubheadding[0] === true ? withsubheadding[1] : ""}
            </span>
                {content.map((value) => {
                    return (<li className="text-ns-pink leading-xl list-outside ml-4">
                                <span className="text-ns-gray font-RotisLight text-sm">{value}</span>
                            </li>)
                })}
            </ul>
        </div>
    </>

)};

export default LeadList;
