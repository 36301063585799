import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import TextGrid from '../TextGrid.js';
import LeadText from '../LeadText.js';
import LeadList from '../LeadList.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_veraenderungsprozesse.jpg';

const VProzesse = () => {

    const listEntrys = ["Organisations- und Personalentwicklung", "Strategieentwicklung", "Agilität und Selbstorganisation", "Kommunikationskonzepte und Meeting-Strukturen", "Führungskultur und Führungsverständnis", "Zusammenarbeit und Teamentwicklung"]

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Chancen durch Wandel effizient nutzen und gestalten."/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={"Veränderungsprozesse"}
            textUpper={"Chancen durch Wandel"}
            textLower={"effizient nutzen und gestalten"}
            />
            <TextGrid>
                <LeadText
                headline="Unternehmenserfolg basiert auf
                vorausschauendem Change Management"
                content="Je größer die Komplexität und Veränderungsdynamik, umso wichtiger
                ist es, den Change wirksam, nachhaltig und effizient zu gestalten.
                Hier gilt es sowohl neue agile Arbeitsmethoden als auch
                bewährte Ansätze erfolgversprechend zu kombinieren. Ich konzipiere
                und begleite Transformationsprozesse ganzheitlich unter Berücksichtigung
                der strategischen, strukturellen und kulturellen Dimensionen
                Ihrer Organisation. Dabei lege ich Wert auf eine gemeinsame,
                kontinuierliche Anpassung sowie eine konsequente Ressourcenorientierung."
                />
                <LeadList
                headline="MÖGLICHE THEMEN:"
                content={listEntrys}
                withsubheadding={[false, ""]}
                />
            </TextGrid>

        </>
)};

export default VProzesse;
