import React from 'react';
import {Link, Match} from '@reach/router';

import Logo_sm from './images/nicolesimon_logo_solo.svg';

const Footer = () => {
    return(
    <>
        <footer>
            <div className="w-full flex flex-col flex-nowrap items-center">
                <img src={Logo_sm} alt="logo-small" className="mx-auto block mt-6 mb-3"></img>
                <Link to="/Kontakt" className="w-full tracking-wide bg-ns-gray-accent text-footerkontakt text-white mt-6 py-2 text-center font-RotisBold">KONTAKT</Link>
                <p className="text-center text-sm tracking-wider pt-4 mt-2 font-RotisLight text-ns-gray">NICOLE SIMON</p>
                <p className="text-center text-sm font-RotisLight tracking-wide text-ns-gray">BERATUNG & ENTWICKLUNG</p>
                <p className="text-center text-sm font-RotisLight text-ns-gray">Ahornweg 33, 71120 Grafenau</p>
                <a href="tel:07033693165" className="text-ns-pink text-sm font-RotisLight underline">Tel.: 07033.693165</a>
                <a href="mailto:info@nicolesimon.de" className="text-ns-pink text-sm font-RotisLight underline pb-4">info@nicolesimon.de</a>
                <hr className="w-full bg-ns-gray-accent mt-3"></hr>
                <div className="pt-3 mb-3">
                    <Match path="/Impressum">
                        {props => props.match ? (
                            <Link to="/Impressum" className="text-ns-pink font-RotisLight tracking-wideopti text-sm">IMPRESSUM | </Link>
                        ) : (
                            <Link to="/Impressum" className="text-ns-gray hover:text-ns-pink font-RotisLight tracking-wideopti text-sm">IMPRESSUM | </Link>
                        )}
                    </Match>
                    <Match path="/Datenschutz">
                            {props => props.match ? (
                                <Link to="/Datenschutz" className="text-ns-pink tracking-wideopti font-RotisLight text-sm">DATENSCHUTZ</Link>
                            ) : (
                                <Link to="/Datenschutz" className="text-ns-gray hover:text-ns-pink tracking-wideopti font-RotisLight text-sm">DATENSCHUTZ</Link>
                            )}
                    </Match>
                </div>
            </div>
        </footer>
    </>

)};

export default Footer;
