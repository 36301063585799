import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import TextGrid from '../TextGrid.js';
import LeadText from '../LeadText.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_konfliktklaerung.jpg';
import LeadList from '../LeadList.js';

const Konflikt = () => {

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Lösungsfokussiert Konflikte klären.
                "/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={"Konfliktklärung"}
            textUpper={"Lösungsfokussiert"}
            textLower={"Konflikte klären"}
            />
            <TextGrid>
                <LeadText
                headline="Konstruktiv und wertschätzend"
                content="Konflikte tauchen überall auf, wo Menschen aufeinandertreffen,
                also auch in Unternehmen und Organisationen und belasten die
                beteiligten Personen. Ziel ist es, professionell und strukturiert mit Konflikten umzugehen und mit den Beteiligten Ideen und Lösungsansätze zu erarbeiten.
                Dabei ist es wichtig, die unterschiedlichen
                Bedürfnisse zu berücksichtigen, allen Perspektiven Raum zu geben
                und Verständnis füreinander aufzubauen. Orientiert an den Zukunftsbildern
                der Beteiligten können ressourcenorientiert Lösungen
                entwickelt sowie Klärungspunkte thematisiert und bearbeitet werden."
                />
                <LeadList
                headline="MÖGLICHE THEMEN:"
                content={["Zwischenmenschliche Konflikte", "Strukturelle Konflikte", "Rollenunklarheiten" ,"Interessenkonflikte", "Generationenkonflikte"]}
                withsubheadding={[false, ""]}
                />
            </TextGrid>

        </>
)};

export default Konflikt;
