import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import RefText from '../RefText.js';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_vita.jpg';

const Vita = () => {
    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Professionalität und Erfahrung"/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={"Vita"}
            textUpper={"Professionalität"}
            textLower={"& Erfahrung"}
            />
            <div className="lg:w-10/12 m-auto pr-4 pl-4">
                <div className="flex flex-col lg:flex-row nowrap">
                    <div className="flex flex-col flex-grow-0 w-full lg:w-1/2 lg:pr-4">
                    <span className="font-RotisBold text-base mb-8 lg:text-lg text-ns-gray">Berufliche Stationen</span>
                        <RefText
                        headline='Selbständige Beraterin und Trainerin'
                        person="seit 2006"
                        abteilung=""
                        />
                        <RefText
                        headline='Mitglied der BPO Beratergruppe'
                        person="seit 2015"
                        abteilung=""
                        />
                        <RefText
                        headline='Dozentin der DHBW CAS Heilbronn'
                        person="seit 2017"
                        abteilung=""
                        />
                        <RefText
                        headline='Interne Personal- und Organisationsentwicklerin'
                        person="bei der Daimler AG in den Bereichen Produktion und Entwicklung"
                        abteilung="1995 - 2006"
                        />
                    </div>
                    <div className="flex flex-col flex-grow-0 w-full lg:w-1/2 lg:pl-4">
                    <span className="font-RotisBold mb-8 mt-10 lg:mt-auto text-base lg:text-lg text-ns-gray">Qualifikation</span>
                        <RefText
                        headline='Studium der Psychologie, mit Schwerpunkt Arbeits- und Organisationspsychologie mit Abschluss Diplom-Psychologin'
                        person="Universität Mannheim // 1989-1995"
                        abteilung=""
                        />
                        <RefText
                        headline='Curriculum Systemische Beratung Teil 1 und 2'
                        person="Institut für systemische Beratung (ISB), Wiesloch // 1996-1998"
                        abteilung=""
                        />
                        <RefText
                        headline='Management von Strukturen und Prozessen'
                        person="Management Zentrum St. Gallen (MZG) // 1998"
                        abteilung=""
                        />
                        <RefText
                        headline='Beratung und Führung'
                        person="Management Center Vorarlberg (MCV), Dornbirn // 1999"
                        abteilung=""
                        />
                        <RefText
                        headline='Coaching und Wissensmanagement'
                        person="C/O/N/E/C/T/A, Wien // 2000"
                        abteilung=""
                        />
                        <RefText
                        headline='Systemische Strukturaufstellungen'
                        person="Angelika Glöckner, Heidelberg // 2001"
                        abteilung=""
                        />
                        <RefText
                        headline='Systemische Transaktionsanalyse'
                        person="Angelika Glöckner, Heidelberg // 2005,"
                        abteilung="Dörte Verres, Heidelberg // 2015"
                        />
                        <RefText
                        headline='Hypnosystemische Weiterbildungen'
                        person="MEIHEI in Heidelberg und MEI Rottweil // 2008-2020"
                        abteilung=""
                        />
                        <RefText
                        headline='Insights-Zertifizierung'
                        person="Scheelen AG, Institut für Managementberatung und Diagnostik //
                        2014"
                        abteilung=""
                        />
                        <RefText
                        headline='Züricher Ressourcen Modell,
                        Motto-Ziele, Motivation und Selbstmanagement'
                        person="Dr. Maja Storch, Heidelberg // 2014"
                        abteilung="Annette Diedrichs, Heidelberg // 2015"
                        />
                        <RefText
                        headline='Lösungsfokus in Organisationen'
                        person="Dr. Susanne Burgstaller, Berlin // 2016"
                        abteilung=""
                        />
                        <RefText
                        headline='Lösungsorientiertes Konfliktmanagement'
                        person="Dr. Peter Röhrig und Mag. Dr. Martina Scheinecker, Trigon Entwicklungsberatung
                        // 2017, 2018, 2020"
                        abteilung=""
                        />
                        <RefText
                        headline='Agile Organisationsstrukturen und Selbstorganisation'
                        person="Florian Rustler, creaffective // 2019, 2020,2021"
                        abteilung=""
                        />
                        <RefText
                        headline='Laufende Teilnahme an Solution Focus Kongressen'
                        person="(SOLDACH // 2017, 2019, 2021) und themenspezifischen Weiterbildungen"
                        abteilung=""
                        />
                        <RefText
                        headline='Laufende professionelle Supervision und Intervision'
                        person=""
                        abteilung=""
                        />
                    </div>
                </div>
            </div>
        </>
)};

export default Vita;
