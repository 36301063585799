import React from 'react';
import { Helmet } from 'react-helmet';


/* Import Components */
import HeroSection from '../HeroSection.js';
import Logo_sm from '../images/nicolesimon_logo_solo.svg';
import Icontel from '../images/nicolesimon_kontakticon_brief.svg';
import Iconbrief from '../images/nicolesimon_kontakticon_tel.svg';

/* Import image */
import HomeHeroImage from '../images/heroImages/header_kontakt.jpg';

const Kontakt = () => {

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="ich freue mich auf Ihre Anfrage"/>
            </Helmet>
            <HeroSection
            image={HomeHeroImage}
            headline={""}
            textUpper={"Ich freue mich auf Ihre Anfrage"}
            />
            <div className="w-full flex flex-col flex-nowrap items-center">
                <p className="text-center text-sm pt-4 font-RotisBold text-ns-gray">NICOLE SIMON</p>
                <p className="text-center text-sm font-RotisBold text-ns-gray">BERATUNG & ENTWICKLUNG</p>
                <p className="text-center text-xs font-RotisLight text-ns-gray">Ahornweg 33<br />71120 Grafenau</p>
                <a href="tel:07033693165" className="text-ns-pink text-xs font-RotisLight underline">Tel.: 07033.693165</a>
                <p className="text-center text-xs font-RotisLight text-ns-gray">Fax.: 07033.693166</p>
                <a href="mailto:info@nicolesimon.de" className="text-ns-pink text-xs font-RotisLight underline">info@nicolesimon.de</a>
                <a href="www.nicolesimon.de" className="text-center font-RotisLight text-ns-gray pb-4">www.nicolesimon.de</a>
            </div>
            <div className="w-full flex items-center contactbtns">
            <a href="tel:07033693165" className="mt-6 mb-6"><img src={Iconbrief} alt="logo-small"></img></a>
            <a href="mailto:info@nicolesimon.de" className="mt-6 mb-6"><img src={Icontel} alt="logo-small"></img></a>
            </div>
            <div className="mb-5 border-ns-pink border-solid border-1 p-7">
                <p className="text-ns-pink font-RotisLight text-xl lg:text-lg text-center">
                    Alle Angebote sind als Präsenz- & Online-Formate möglich!
                </p>
            </div>
            <img src={Logo_sm} alt="logo-small" className="mx-auto block mt-6 mb-6"></img>
        </>
)};

export default Kontakt;
